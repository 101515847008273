import React, { useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby-link';
import moment from 'moment';
import { useQueryParam } from 'gatsby-query-params';
import { connect, Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Button } from '@mui/material';

import { getCookie, setCookie } from '../../api/dataApi';
import * as webSocketActions from '../../redux/actions/webSocketActions';

import EventRegistrationPopup from './EventRegistrationPopup';

function EventCalendar({ roadmap, roadmapKey, categoryKey, title }) {
  const [eventList, setEventList] = useState(null);
  const [calendarDateSelected, setCalendarDateSelected] = useState(null);
  const [userInfo, setUserInfo] = useState({});
  const [showRegistrationPopup, setShowRegistrationPopup] = useState(null);

  useEffect(() => {
    if (roadmap && roadmap.EventList) {
      setEventList(
        roadmap.EventList.filter((event) => event.CategoryKey === categoryKey)
      );
    }
  }, [roadmap]);

  useEffect(() => {
    if (eventList && eventList.length && !calendarDateSelected) {
      setCalendarDateSelected(eventList[0].Date);
    }
  }, [eventList]);

  useEffect(() => {
    if (userInfo && Object.keys(userInfo).length) {
      console.log('userInfo', userInfo);

      const userInfoJson = JSON.stringify(userInfo);
      setCookie('mi-reg-info', btoa(userInfoJson));
    }
  }, [userInfo]);

  useEffect(() => {
    try {
      const userInfoJson = atob(getCookie('mi-reg-info'));
      if (userInfoJson) {
        const _userInfo = JSON.parse(userInfoJson);
        if (_userInfo) {
          setUserInfo(_userInfo);
        }
      }
    } catch (e) {}
  }, []);

  return eventList && calendarDateSelected ? (
    <div className="event-calendar">
      <div className="date-picker">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StaticDatePicker
            orientation="landscape"
            openTo="day"
            disableHighlightToday
            defaultCalendarMonth={
              parseInt(moment(eventList[0].Date).format('M')) + 1
            }
            value={calendarDateSelected}
            shouldDisableDate={(date) => {
              if (
                eventList.filter((event) =>
                  moment(event.Date).isSame(moment(date))
                ).length
              ) {
                return false;
              }

              return true;
            }}
            views={['day']}
            onChange={(date) => {
              setCalendarDateSelected(date);
            }}
          />
        </LocalizationProvider>
      </div>
      <div className="event-list">
        <h2 className="text">{title} Meetings</h2>
        {eventList.map((event, eventIndex) => (
          <>
            {!eventList[eventIndex - 1] ||
            eventList[eventIndex - 1].Date !== event.Date ? (
              <>
                <br />
                <div className="date text">
                  {moment(event.Date).format('MMMM Do')}
                </div>
              </>
            ) : null}
            <div className="timeframe">
              <Button
                variant={
                  userInfo && userInfo.EventKey === event.EventKey
                    ? 'contained'
                    : 'outlined'
                }
                onClick={() => {
                  setShowRegistrationPopup(event);
                }}
                className={
                  userInfo && userInfo.EventKey === event.EventKey
                    ? 'icon'
                    : 'text'
                }>
                {event.Timeframe}
              </Button>
            </div>
          </>
        ))}
      </div>

      {showRegistrationPopup ? (
        <EventRegistrationPopup
          closeFunc={() => setShowRegistrationPopup(null)}
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          roadmapKey={roadmapKey}
          roadmap={roadmap}
          event={showRegistrationPopup}
          title={title}
        />
      ) : null}
    </div>
  ) : null;
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig,
    authUser: state.authUser
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventCalendar);
