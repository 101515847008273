import React, { useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby-link';
import moment from 'moment';
import { connect, Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Alert
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import * as webSocketActions from '../../redux/actions/webSocketActions';

import { teams } from '../CompanyProfile/Team';
import { validateEmail } from '../../api/userAuthApi';
import { registerForRoadmapEvent } from '../../api/companyDataApi';

function EventRegistrationPopup({
  closeFunc,
  userInfo,
  setUserInfo,
  roadmapKey,
  roadmap,
  event,
  title,
  actions
}) {
  const [editRegistration, setEditRegistration] = useState(null);
  const [registrationError, setRegistrationError] = useState(null);

  return roadmap && roadmap.Roadmap ? (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      className="roadmap-registration-popup"
      fullWidth={true}>
      <DialogTitle id="form-dialog-title">
        <IconButton
          style={{ float: 'right' }}
          aria-label="close"
          onClick={() => {
            closeFunc();
          }}>
          <CloseIcon />
        </IconButton>
        <img src={withPrefix('/images/logo-icon.svg')} className="logo-icon" />
        <div>Confirm Registration</div>
      </DialogTitle>
      <DialogContent
        width="100%"
        style={{ paddingTop: '20px', backgroundColor: '#f8f8f8' }}>
        <div className="roadmap-registration">
          {teams &&
          teams.sales &&
          teams.sales.members &&
          teams.sales.members[roadmap.Roadmap.Owner] ? (
            <div className="owner">
              <img
                src={teams.sales.members[roadmap.Roadmap.Owner].image}
                style={{
                  backgroundColor:
                    teams.sales.members[roadmap.Roadmap.Owner].color
                }}
              />
              <span className="hand">
                {teams.sales.members[roadmap.Roadmap.Owner].name} Hosting
              </span>
            </div>
          ) : null}

          <h2>{title}</h2>
          <h3>
            {moment(event.Date).format('dddd MMMM Do')} from {event.StartTime}{' '}
            to {event.EndTime} MST
          </h3>

          {userInfo.EventKey === event.EventKey && !editRegistration ? (
            <Alert style={{ marginTop: '20px' }}>
              Thank you! You are signed up to attend this meeting. Please be
              sure to check your email to confirm your invitation.
              <div style={{ textAlign: 'center' }}>
                <Button
                  onClick={() => setEditRegistration(true)}
                  style={{ marginLeft: '-40px' }}>
                  Edit Reservation
                </Button>
              </div>
            </Alert>
          ) : (
            <>
              {registrationError ? (
                <Alert severity="error">{registrationError}</Alert>
              ) : null}

              <TextField
                value={userInfo.FirstName}
                onChange={(event) =>
                  setUserInfo({ ...userInfo, FirstName: event.target.value })
                }
                fullWidth
                required
                label="First Name"
              />

              <TextField
                value={userInfo.LastName}
                onChange={(event) =>
                  setUserInfo({ ...userInfo, LastName: event.target.value })
                }
                fullWidth
                required
                label="Last Name"
              />

              <TextField
                value={userInfo.Email}
                onChange={(event) =>
                  setUserInfo({ ...userInfo, Email: event.target.value })
                }
                type="email"
                fullWidth
                required
                label="Email"
              />

              {event.CategoryKey === 'property' ? (
                <TextField
                  value={userInfo.PropertyName}
                  onChange={(event) =>
                    setUserInfo({
                      ...userInfo,
                      PropertyName: event.target.value
                    })
                  }
                  fullWidth
                  required
                  label="Property Name"
                />
              ) : null}
            </>
          )}
        </div>
      </DialogContent>
      <DialogActions style={{ display: 'block', textAlign: 'center' }}>
        {userInfo.EventKey !== event.EventKey || editRegistration ? (
          <Button
            onClick={() => {
              setRegistrationError(null);
              actions.pageLoading(true, 'Saving...');
              registerForRoadmapEvent(roadmapKey, {
                ...userInfo,
                EventKey: event.EventKey
              }).then((result) => {
                actions.pageLoading(false);

                if (result === 'SUCCESS') {
                  setUserInfo({ ...userInfo, EventKey: event.EventKey });
                  setEditRegistration(null);
                } else {
                  setRegistrationError(result);
                }
              });
            }}
            variant="contained"
            disabled={
              !userInfo.FirstName ||
              !userInfo.LastName ||
              !userInfo.Email ||
              !validateEmail(userInfo.Email) ||
              !userInfo.PropertyName
            }>
            Sign Up for Meeting
          </Button>
        ) : (
          <Button onClick={() => closeFunc()} variant="outlined">
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  ) : null;
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig,
    authUser: state.authUser
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventRegistrationPopup);
