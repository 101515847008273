import React, { useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby-link';
import moment from 'moment';
import { useQueryParam } from 'gatsby-query-params';
import { connect, Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';
import ChevronRight from '@mui/icons-material/ChevronRight';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { LinearProgress, Button } from '@material-ui/core';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { getCompany, getRoadmap } from '../../api/companyDataApi';
import { getCookie, scrollToElementInPage } from '../../api/dataApi';

import * as webSocketActions from '../../redux/actions/webSocketActions';

import EventCalendar from './EventCalendar';
import Team from '../CompanyProfile/Team';

import './index.css';

function CompanyRoadmap({ siteConfig, webSocket, actions }) {
  const roadmapKey = useQueryParam('roadmap', null);
  const requestedMode = useQueryParam('mode', null);
  const [roadmap, setRoadmap] = useState(null);
  const [showSampleText, setShowSampleText] = useState(null);
  const [mode, setMode] = useState(null);
  const [expanded, setExpanded] = useState({});

  useEffect(() => {
    if (webSocket.connected) {
      if (!roadmap) {
        getRoadmap(roadmapKey).then((_roadmap) => {
          setRoadmap(_roadmap);
          document.title =
            _roadmap.Roadmap.ManagementCompanyName +
            ' Onboarding Roadmap | ' +
            siteConfig.brand;
        });
      }
    }
  }, [roadmapKey, webSocket]);

  useEffect(() => {
    setMode(requestedMode ? requestedMode : null);
  }, [requestedMode]);

  function getEventName(monthIndex, dateIndex) {
    const monthList = Object.keys(roadmap.Events);
    const month = monthList[monthIndex];
    if (roadmap && roadmap.Events && roadmap.Events[month]) {
      const dateList = Object.keys(roadmap.Events[month]);
      const date = dateList[dateIndex];
      const eventName = roadmap.Events[month][date].length
        ? roadmap.Events[month][date][0].EventName
        : null;

      if (eventName) {
        let prevDate = dateList[dateIndex - 1] ? dateList[dateIndex - 1] : null;
        let prevEventName =
          roadmap.Events[month][prevDate] &&
          roadmap.Events[month][prevDate].length
            ? roadmap.Events[month][prevDate][0].EventName
            : null;

        if (!prevDate) {
          const prevMonth = monthList[monthIndex - 1];
          if (prevMonth) {
            const prevDateList = Object.keys(roadmap.Events[prevMonth]);
            if (prevDateList) {
              prevDate = prevDateList[prevDateList.length - 1];
              if (prevDate) {
                prevEventName =
                  roadmap.Events[prevMonth][prevDate] &&
                  roadmap.Events[prevMonth][prevDate].length
                    ? roadmap.Events[prevMonth][prevDate][0].EventName
                    : null;
              }
            }
          }
        }

        if (!prevEventName || prevEventName !== eventName) {
          return <div className="event-name">{eventName}</div>;
        }
      }
    }

    return null;
  }

  function scrollToSection(section) {
    const element = document.getElementById('section-' + section);
    if (element) {
      scrollToElementInPage(element, -100, false);
    }
  }

  function getEventByKey(eventKey) {
    let _event = null;

    if (roadmap && roadmap.Events) {
      Object.keys(roadmap.Events).map((month) => {
        Object.keys(roadmap.Events[month]).map((date) => {
          if (
            !_event &&
            roadmap.Events[month][date] &&
            roadmap.Events[month][date].length
          ) {
            _event = roadmap.Events[month][date].filter(
              (event) => event.EventKey === eventKey
            )[0];
          }
        });
      });
    }

    return _event;
  }

  function getEventDate(eventKey) {
    const event = getEventByKey(eventKey);
    if (event) {
      return moment(event.Date).format('MMMM D');
    } else {
      console.warn('no event found for ' + eventKey);
    }

    return '';
  }

  function getEmailDate(emailKey) {
    if (roadmap) {
      if (roadmap.Emails && roadmap.Emails[emailKey]) {
        return moment(roadmap.Emails[emailKey]).format('MMMM D');
      } else {
        console.warn('no email found for ' + emailKey);
      }
    }

    return '';
  }

  return (
    <div className={'company-page roadmap' + (roadmap ? ' initted' : '')}>
      {!roadmap ? (
        <LinearProgress />
      ) : (
        <div className="section hero">
          <div className="bubbles">
            <img src={withPrefix('/images/company/bubble-hero.svg')} />
            <img src={withPrefix('/images/company/bubble-hero.svg')} />
            <img src={withPrefix('/images/company/bubble-hero.svg')} />
            <img src={withPrefix('/images/company/bubble-hero.svg')} />
            <img src={withPrefix('/images/company/bubble-hero.svg')} />
            <img src={withPrefix('/images/company/bubble-hero.svg')} />
            <img src={withPrefix('/images/company/bubble-hero.svg')} />
            <img src={withPrefix('/images/company/bubble-hero.svg')} />
            <img src={withPrefix('/images/company/bubble-hero.svg')} />
            <img src={withPrefix('/images/company/bubble-hero.svg')} />
            <img src={withPrefix('/images/company/bubble-hero.svg')} />
            <img src={withPrefix('/images/company/bubble-hero.svg')} />
            <img src={withPrefix('/images/company/bubble-hero.svg')} />
            <img src={withPrefix('/images/company/bubble-hero.svg')} />
            <img src={withPrefix('/images/company/bubble-hero.svg')} />
            <img src={withPrefix('/images/company/bubble-hero.svg')} />
            <img src={withPrefix('/images/company/bubble-hero.svg')} />
            <img src={withPrefix('/images/company/bubble-hero.svg')} />
            <img src={withPrefix('/images/company/bubble-hero.svg')} />
            <img src={withPrefix('/images/company/bubble-hero.svg')} />
          </div>

          <div className="section-content">
            <div className="hero-content">
              <h1>
                {roadmap.Roadmap.LogoFileWeb ? (
                  <img
                    src={roadmap.Roadmap.LogoFileWeb}
                    alt={roadmap.Roadmap.ManagementCompanyName}
                  />
                ) : (
                  roadmap.Roadmap.ManagementCompanyName
                )}
              </h1>
              <h2>
                Roadmap to Onboarding the{' '}
                <img
                  className="logo"
                  alt={siteConfig.brand}
                  src={withPrefix(siteConfig.logo)}
                />{' '}
                Platform
              </h2>
              <ul className="roadmap-summary">
                <li>
                  <div className="bordered corporate">
                    <div className="icon">
                      <img
                        src={withPrefix('/images/roadmap/relationship.png')}
                      />
                    </div>
                    <div className="text">
                      Corporate
                      <br />
                      Integration
                    </div>
                  </div>
                  <Button onClick={() => scrollToSection('corporate')}></Button>
                </li>
                <li>
                  <ChevronRight className="step-arrow" />
                  <div className="bordered regional">
                    <div className="icon">
                      <img
                        src={withPrefix('/images/roadmap/video-conference.png')}
                      />
                    </div>
                    <div className="text">
                      Regional
                      <br />
                      Orientation
                    </div>
                  </div>
                  <Button onClick={() => scrollToSection('regional')}></Button>
                </li>
                <li>
                  <ChevronRight className="step-arrow" />
                  <div className="bordered property">
                    <div className="icon">
                      <img src={withPrefix('/images/roadmap/onboarding.png')} />
                    </div>
                    <div className="text">
                      Property
                      <br />
                      Onboarding
                    </div>
                  </div>
                  <Button onClick={() => scrollToSection('property')}></Button>
                </li>
                <li>
                  <ChevronRight className="step-arrow" />
                  <div className="bordered launch">
                    <div className="icon">
                      <img src={withPrefix('/images/roadmap/rocket.png')} />
                    </div>
                    <div className="text">
                      {siteConfig.brand}
                      <br />
                      Goes LIVE
                    </div>
                  </div>
                  <Button onClick={() => scrollToSection('launch')}></Button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {roadmap && roadmap.Events ? (
        <div className="section timeline">
          <div className="timeline-container">
            {Object.keys(roadmap.Events).map((month, monthIndex) => (
              <div className="event-month">
                <div className="month-name">
                  <span>{month}</span>
                </div>
                <div className="days">
                  {Object.keys(roadmap.Events[month]).map((date, dateIndex) => (
                    <div className="day">
                      <Button
                        className={
                          'bordered ' +
                          (roadmap.Events[month][date].length
                            ? roadmap.Events[month][date][0].CategoryKey
                            : '')
                        }
                        onClick={() => {
                          if (
                            roadmap.Events[month][date].length &&
                            roadmap.Events[month][date][0].CategoryKey
                          ) {
                            scrollToSection(
                              roadmap.Events[month][date][0].CategoryKey
                            );
                          }
                        }}
                        disabled={!roadmap.Events[month][date].length}>
                        {moment(date).format('D')}
                      </Button>

                      {getEventName(monthIndex, dateIndex)}
                    </div>
                  ))}
                </div>
              </div>
            ))}

            <div className="mobile-prompt">
              Scroll right to view timeline <ArrowRightAltIcon />
            </div>
          </div>
        </div>
      ) : null}

      {roadmap ? (
        <div
          className={
            (mode ? 'collapsible' : '') +
            (expanded.corporate ? ' expanded' : '')
          }>
          <div id="section-corporate" className="section sub corporate">
            <div className="section-content">
              <h2
                onClick={() => {
                  setExpanded({ ...expanded, corporate: !expanded.corporate });
                }}>
                <div className="icon">
                  <img src={withPrefix('/images/roadmap/relationship.png')} />
                </div>
                <div className="text">
                  Corporate Integration{' '}
                  <KeyboardArrowDownIcon className="expand-icon" />
                </div>

                <ol>
                  <li>
                    <CheckBoxOutlinedIcon />
                    {roadmap.Roadmap.ManagementCompanyName} gives{' '}
                    {siteConfig.brand} 'web services' access to resident data
                  </li>
                  <li>
                    <CheckBoxOutlinedIcon />
                    {roadmap.Roadmap.ManagementCompanyName} provides{' '}
                    {siteConfig.brand} with:
                    <ul>
                      <li>The name and email of each Regional Director</li>
                      <li>
                        The name and address of each property each Regional
                        Director oversees
                      </li>
                      <li>
                        The name and email of the Community Director at each of
                        those properties
                      </li>
                    </ul>
                  </li>
                  <li>
                    <CheckBoxOutlinedIcon />
                    {roadmap.Roadmap.ManagementCompanyName} notifies each
                    Regional Director by{' '}
                    <strong>
                      {' '}
                      {getEmailDate('regional-director-email-corporate')}
                    </strong>{' '}
                    that the {siteConfig.brand} platform is being implemented at
                    each of their properties in the very near future and to
                    expect an email from {siteConfig.brand} on{' '}
                    <strong>
                      {getEmailDate('regional-director-email-welcome')}
                    </strong>{' '}
                    explaining the onboarding process.
                    <br />
                    <br />
                    For your convenience, {siteConfig.brand} has prepared some
                    sample text for the above email.{' '}
                    <a
                      className="link"
                      onClick={() =>
                        setShowSampleText('regional-director-email-corporate')
                      }>
                      Click here to view.
                    </a>
                  </li>
                </ol>
              </h2>
            </div>
          </div>
          <div className="section mi-task">
            <div className="section-content">
              <img
                className="logo"
                alt={siteConfig.brand}
                src={withPrefix(siteConfig.logo)}
              />
              <p>
                On{' '}
                <strong>
                  {getEmailDate('regional-director-email-welcome')}
                </strong>{' '}
                {siteConfig.brand} will send each regional a "Getting to know{' '}
                {siteConfig.brand}" email introducing our services and
                explaining the onboarding process. That email will contain a
                link to this page so everyone sees the same onboarding schedule.
                <br /> <br />
                <a
                  className="link"
                  onClick={() =>
                    setShowSampleText('regional-director-email-welcome')
                  }>
                  Click here to view a sample of that email.
                </a>
              </p>
            </div>
          </div>
        </div>
      ) : null}

      {roadmap ? (
        <div
          className={
            (mode && mode !== 'regional' ? 'collapsible regional' : '') +
            (expanded.regional ? ' expanded' : '')
          }>
          <div id="section-regional" className="section sub regional">
            <div className="section-content">
              <h2
                onClick={() => {
                  setExpanded({ ...expanded, regional: !expanded.regional });
                }}>
                <div className="icon">
                  <img
                    src={withPrefix('/images/roadmap/video-conference.png')}
                  />
                </div>
                <div className="text">
                  Regional Director Orientation{' '}
                  <KeyboardArrowDownIcon className="expand-icon" />
                </div>
              </h2>

              <p>Welcome to {siteConfig.brand}!</p>
              <p>
                {siteConfig.brand} is an online ‘new resident’ onboarding
                platform that will assist your soon-to-be new residents with
                their upcoming move by helping them effectively manage the
                entire process, step-by-step, in the most efficient and least
                stressful way possible. The {siteConfig.brand} platform will
                benefit you and your onsite team as well by automating incoming
                resident tracking, pre-move in compliance status, and document
                retrieval and management.
              </p>
              <p>
                <a className="link" href={'/platform-overview'} target="_blank">
                  Click here
                </a>{' '}
                for a visual summary of the {siteConfig.brand} platform.{' '}
              </p>
              <p>
                Once you familiarize yourself with the basic concept behind{' '}
                {siteConfig.brand} please choose one of the "Getting to know{' '}
                {siteConfig.brand}" google meetings below so that we can go over
                our platform and services in greater detail and better explain
                the benefits to both you and your residents.{' '}
              </p>
              <p>
                If you are unable to attend any of the meetings below, please
                reach out to us at{' '}
                <a
                  href={'mailto:' + roadmap.Roadmap.SupportEmail}
                  className="link">
                  {roadmap.Roadmap.SupportEmail}
                </a>{' '}
                to schedule a day and time that works better for you.
              </p>

              <EventCalendar
                roadmapKey={roadmapKey}
                roadmap={roadmap}
                categoryKey={'regional'}
                title={'"Getting to Know ' + siteConfig.brand + '"'}
              />

              <p>
                After attending a meeting please be sure to let your properties
                know to expect an email from {siteConfig.brand} on{' '}
                <strong> {getEmailDate('property-email-welcome')}</strong>.
                <br />
                <br />
                For your convenience, {siteConfig.brand} has prepared some
                sample text for the above email.{' '}
                <a
                  className="link"
                  onClick={() => setShowSampleText('property-email-regional')}>
                  Click here to view.
                </a>
              </p>
            </div>
          </div>
          <div className="section mi-task">
            <div className="section-content">
              <img
                className="logo"
                alt={siteConfig.brand}
                src={withPrefix(siteConfig.logo)}
              />
              <p>
                On <strong>{getEmailDate('property-email-welcome')}</strong>{' '}
                {siteConfig.brand} will send each property a "Welcome to{' '}
                {siteConfig.brand}" email introducing our services and
                explaining the onboarding process. That email will contain a
                link to this page so everyone sees the same onboarding schedule.
                <br /> <br />
                <a
                  className="link"
                  onClick={() => setShowSampleText('property-email-welcome')}>
                  Click here to view a sample of that email.
                </a>
              </p>
            </div>
          </div>
        </div>
      ) : null}

      {roadmap ? (
        <div id="section-property" className="section sub property">
          <div className="section-content">
            <h2>
              <div className="icon">
                <img src={withPrefix('/images/roadmap/onboarding.png')} />
              </div>
              <div className="text">Property Onboarding</div>
            </h2>

            <p>Welcome to {siteConfig.brand}!</p>
            <p>
              {siteConfig.brand} is an online ‘new resident’ onboarding platform
              that will assist your soon-to-be new residents with their upcoming
              move by helping them effectively manage the entire process,
              step-by-step, in the most efficient and least stressful way
              possible. The {siteConfig.brand} platform will benefit you and
              your onsite team as well by automating incoming resident tracking,
              pre-move in compliance status, and document retrieval and
              management.
            </p>
            <p>
              <a className="link" href={'/platform-overview'} target="_blank">
                Click here
              </a>{' '}
              for a visual summary of the {siteConfig.brand} platform.{' '}
            </p>
            <p>
              <a className="link" href={'/'} target="_blank">
                Click here
              </a>{' '}
              to learn more about what the residents will see.
            </p>

            <p>
              Once you familiarize yourself with the basic concept behind{' '}
              {siteConfig.brand} please choose one of the "Welcome to{' '}
              {siteConfig.brand}" google meetings below so that we can go over
              our platform and services in greater detail and better explain the
              benefits to both you and your residents.{' '}
            </p>

            <p>
              If you are unable to attend any of the meetings below, please
              reach out to us at{' '}
              <a
                href={'mailto:' + roadmap.Roadmap.SupportEmail}
                className="link">
                {roadmap.Roadmap.SupportEmail}
              </a>{' '}
              to schedule a day and time that works better for you.
            </p>

            <EventCalendar
              roadmapKey={roadmapKey}
              roadmap={roadmap}
              categoryKey={'property'}
              title={'"Welcome to ' + siteConfig.brand + '"'}
            />

            <p>
              At the end of the "Welcome to {siteConfig.brand}" meeting, we will
              walk those who stay on through the property setup process.{' '}
            </p>
            <p>
              <strong>
                {' '}
                If you successfully completed and submitted the property setup
              </strong>{' '}
              you will receive an email from {siteConfig.brand} once we have had
              a chance to review it and confirm that everything looks good. If
              it does, we will start emailing new residents an invitation to use
              our site starting <strong>{getEventDate('launch')}</strong>.
            </p>
            <p>
              <strong>If you did not complete the property setup</strong> during
              the call you can click the "Property setup" link in the email we
              sent you on{' '}
              <strong>{getEmailDate('property-email-welcome')}</strong> and do
              it at your convenience. It should only take 10-15 minutes.
            </p>
            <p>
              If you would like to set up a one-on-one call to go over it,
              please reach out to us at{' '}
              <a
                href={'mailto:' + roadmap.Roadmap.SupportEmail}
                className="link">
                {roadmap.Roadmap.SupportEmail}
              </a>{' '}
              and schedule a day and time that works better for you.
            </p>
            <p>
              In order to stay on schedule, it is important that you complete
              the property setup by <strong>{getEventDate('review-1')}</strong>{' '}
              so that we can review it before going live on{' '}
              <strong>{getEventDate('launch')}</strong>.
            </p>
          </div>
        </div>
      ) : null}

      {/* <div id="section-review" className="section sub review">
        <div className="section-content">
          <h2>
            <div className="icon">
              <img src={withPrefix('/images/roadmap/test.png')} />
            </div>
            <div className="text">{siteConfig.brand} Review</div>
          </h2>

          <p>
            Before going live, {siteConfig.brand} will review each property…
          </p>
        </div>
      </div> */}

      <div id="section-launch" className="section sub launch">
        <div className="section-content">
          <h2>
            <div className="icon">
              <img src={withPrefix('/images/roadmap/rocket.png')} />
            </div>
            <div className="text">
              {siteConfig.brand} Goes LIVE on {getEventDate('launch')}!
            </div>
          </h2>

          <p>
            Assuming we have everything we need, and your property passes the{' '}
            {siteConfig.brand} review, your soon-to-be new residents, those that
            have yet to move-in, will begin receiving email invites from us
            welcoming them to their new home and introducing them to the{' '}
            {siteConfig.brand} dashboard on {getEventDate('launch')}.
          </p>

          <p>
            Then, as you approve new leases, your onsite management software
            will automatically send us that new resident's data and the{' '}
            {siteConfig.brand} platform will automatically send each of them an
            email introduction and invitation to use the {siteConfig.brand}{' '}
            dashboard.
            <br /> <br />
            <a
              className="link"
              href={'/resident-email-process'}
              target="_blank">
              Click here to view a timeline of resident communication.
            </a>
            <br /> <br />
            <a
              className="link"
              href={withPrefix('/images/company/Resident-Flyer.pdf')}
              target="_blank">
              Click here to view the Resident Flyer for onsite welcome packages.
            </a>
          </p>
        </div>
      </div>

      <Team siteConfig={siteConfig} />

      {showSampleText ? (
        <Dialog
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          className="roadmap-sample-email">
          <DialogTitle id="form-dialog-title">
            <IconButton
              style={{ float: 'right' }}
              aria-label="close"
              onClick={() => {
                setShowSampleText(null);
              }}>
              <CloseIcon style={{ marginTop: '10px' }} />
            </IconButton>
            <img
              src={withPrefix('/images/logo-icon.svg')}
              className="logo-icon"
            />
            Sample Email
          </DialogTitle>
          <DialogContent width="100%" style={{ paddingTop: '20px' }}>
            <iframe
              frameBorder="none"
              scrolling="auto"
              width="100%"
              height="600"
              style={{ width: '100%', height: '70vh' }}
              src={
                process.env.GATSBY_API_URL +
                '/Frameable/RoadmapEmail?roadmap=' +
                roadmapKey +
                '&visitorKey=' +
                getCookie('mi-visitor') +
                '&emailKey=' +
                showSampleText
              }></iframe>
          </DialogContent>
          <DialogActions style={{ display: 'block', textAlign: 'right' }}>
            <Button
              onClick={() => {
                setShowSampleText(null);
              }}
              variant="outlined">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig,
    authUser: state.authUser
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyRoadmap);
